import styled from 'styled-components'

export const Tagline = styled.h1`
  font-size: 4rem;
  color: white;
`

export const TaglineSubHeading = styled.p`
  font-size: 2.5rem;
  color: #f0f0f0;
  margin-bottom: 1.5rem;
`

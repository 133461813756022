import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from "../components/seo"
import Img from 'gatsby-image'

import { useStaticQuery, graphql } from 'gatsby'

import Container from "../components/library/container";
import { Tagline, TaglineSubHeading } from "../components/library/headings";


const Section = styled.section`
  padding: 2rem 1rem;
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }
`

const Hero = styled(Section)`
  height: 100vh;
  width: 100%;
  display: flex;
  background: #222;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const HeroBackground = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`

const HeroContent = styled(Container)`
  z-index: 1;
`

const CallToAction = styled.div`
  padding-top: 4rem;
`

const LinkButton = styled.a`
  margin: 2rem 0;
  padding: 1rem 2rem;
  background: #fa0303;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.8rem;
`

const About = styled(Section)``

const AboutContainer = styled(Container)`
  display: flex;
`

const AboutUsText = styled.div`
  margin: auto;
  width: 40rem;
`

const AboutUsImg = styled.div``

const Contact = styled(Section)`
  display: flex;
  justify-content: center;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactUsImg = styled.div``

export default function Index() {
  const { backgroundHd, background4k } = useStaticQuery(graphql`
    query {
      backgroundHd: file(relativePath: { eq: "background_hd.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      background4k: file(relativePath: { eq: "background_4k.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const sources = [
    backgroundHd.childImageSharp.fluid,
    {
      ...background4k.childImageSharp.fluid,
      media: `(min-width: 1920w)`,
    },
  ]

  return (
    <Layout>
      <SEO title="Welcome to Acorp" />
      <Hero>
        <HeroBackground>
          <Img fluid={sources} description="background" />
        </HeroBackground>
        <HeroContent>
          <Tagline>Experience</Tagline>
          <Tagline>Personal Consulting</Tagline>
          <TaglineSubHeading>We help you desigining you success</TaglineSubHeading>
          <CallToAction>
            <LinkButton href="#contact-us">Contact Us</LinkButton>
          </CallToAction>
        </HeroContent>
      </Hero>
      <About>
        <AboutContainer>
          <AboutUsText>
            <p>
              Acorp is a thought of young Indians innovating themselves into the
              next generation, wherein thoughts are understood by machines to enrich
              people’s lives and contribute to social prosperity by creating a
              pioneering future.
            </p>
            <p>
              We specialize in bringing together the brightest and the most
              experienced industry leaders and management consultants to meet the
              ever-challenging environments facing top organizations today.
            </p>
            <CallToAction>
              <LinkButton href="#contact-us">Learn More</LinkButton>
            </CallToAction>
          </AboutUsText>
        </AboutContainer>
      </About>
      <About>
        <AboutContainer>
          <AboutUsText>
            <p>
              We are an establishment with a communal motive to augment skills and
              passion of aspiring entrepreneurs to achieve their dream career and
              company and providing industries with quality products and services
              to meet the growing demand.
        </p>
            <p>
              Our products & services are well designed, customized and emergent
              according to the need of entrepreneurial demands. We help our
              customers make important decisions with regards to overall enterprise
              applications strategy as well as guide them through planning & process
              design, re-design and full implementation life-cycle. We deliver
              several platform to aspiring entrepreneurs to advance their experience
              and enhance their skills in successfully competing with the established
              industry.
        </p>
          </AboutUsText>
        </AboutContainer>

        <AboutUsImg />
      </About>
      <Contact id="contact-us">
        <ContactInfo>
          <LinkButton href="https://wa.me/919886331224">Reach Us on WhatsApp</LinkButton>
          <LinkButton>Drop a line via Email</LinkButton>
        </ContactInfo>
        <ContactUsImg />
      </Contact>
    </Layout>
  )
}